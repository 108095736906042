import React, { FC, useState, useCallback, useMemo } from 'react';

import Title from './ui/Title';

const App: FC = () => {
  const [primary, setPrimary] = useState(true);

  const renderTitle = useMemo(() => {
    return primary ? 'Hubble' : 'CICD';
  }, [primary]);

  const handleClickTitle = useCallback(() => {
    setPrimary(!primary);
  }, [primary]);

  return <Title onClick={handleClickTitle}>{renderTitle}</Title>;
};

export default App;
