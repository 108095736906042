import styled from 'styled-components';

const Title = styled.h1`
  font-family: Helvetica;
  font-size: 10em;
  letter-spacing: -8px;
  text-align: center;
  cursor: pointer;
  color: #009ba4;
`;

export default Title;
